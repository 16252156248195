<template>
  <v-container fluid>
    <v-layout align-start justify-center fill-height row wrap>
      <v-flex xs10 class="mt-10">
        <v-form ref="form" lazy-validation>
          <v-card flat>
            <v-card-title>
              <img class="login-logo" :src="logo" :height="100" :width="100" />
              <h1>Manager</h1>
              <h2 class="title">
                Connexion
              </h2>
            </v-card-title>
            <v-card-text>
              <v-flex xs8 md6 offset-xs2 offset-md3>
                <v-text-field
                  v-model.trim="identifiant"
                  label="Email"
                  prepend-icon="mdi-face"
                  autofocus
                  :error-messages="idErrors"
                />
              </v-flex>
              <v-flex xs8 md6 offset-xs2 offset-md3>
                <v-text-field
                  v-model.trim="password"
                  label="Mot de passe"
                  type="password"
                  prepend-icon="mdi-key"
                  :error-messages="passwordErrors"
                  @keyup.enter="login"
                />
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-layout wrap align-center justify-center>
                <!-- <v-flex xs12 md5 lg3 class="text-xs-center text-md-right">
                          <v-btn color="warning" flat @click="dialog = !dialog">
                            Mot de passe oublié
                            <v-icon right class="ml-2">mdi-emoticon-dead</v-icon>
                          </v-btn>
                        </v-flex>
                    <v-spacer />-->
                <v-flex xs12 md5 lg4 class="text-xs-center">
                  <v-btn
                    color="success"
                    center
                    :loading="loading"
                    :disabled="
                      $v.identifiant.$invalid || $v.password.$invalid || loading
                    "
                    @click="login()"
                  >
                    <v-icon>mdi-login</v-icon>
                    <v-flex class="ml-2 pa-0">
                      Authentification
                    </v-flex>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-card flat class="contact-card">
          <v-divider />
          <v-card-title>
            <h2 class="title">
              Nous contacter
            </h2>
          </v-card-title>
          <v-card-text>
            <v-layout wrap align-center justify-center>
              <v-flex xs12 md5 lg4 class="text-xs-center">
                <p>Vous rencontrez un problème ?</p>
                <p>Cliquer sur le bouton ci-dessous afin de nous contacter.</p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout wrap align-center justify-center>
              <v-flex xs12 md5 lg4 class="text-xs-center">
                <v-btn
                  center
                  color="info"
                  :loading="loading"
                  @click="message()"
                >
                  <v-icon>mdi-send</v-icon>
                  <v-flex class="ml-2 pa-0">
                    Nous contacter
                  </v-flex>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <v-dialog v-model="dialog" max-width="500px">
      <v-card class="px-3">
        <v-card-title>
          <h2 class="headline my-0">
            Demande de nouveau mot de passe
            <v-icon right class="ml-2">mdi-key</v-icon>
          </h2>
        </v-card-title>

        <v-card-text>
          <p class="mx-3 mt-0 mb-3">
            Pour récupérer un nouveau mot de passe,
            veuillez indiquer votre adresse email
            <em>(celle qui vous sert à vous connecter sur cet outil)</em>.
          </p>
          <v-text-field
            v-model.trim="email"
            label="Votre email"
            type="email"
            prepend-icon="mdi-email"
            :error-messages="resetpasswordErrors"
            @keyup.enter="getPwd"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn color="warning" flat @click="dialog = false">
            <v-icon class="mr-2">mdi-cancel</v-icon>Annuler
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            :loading="loading_reset"
            :disabled="$v.email.$invalid || loading_reset"
            @click="getPwd"
          >
            Je veux un nouveau mot de passe
            <v-icon class="ml-2" right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->

    <ui-SnackBarV2
      :text="text"
      :color="color"
      :bottom="bottom"
      :top="top"
      :right="right"
      :left="left"
      :snackbar="snackbar"
    />
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin';
import { /*mapState, */ mapMutations, mapActions } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'Login',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({
    bg: './img/white-bg.png',
    // dialog: false,
    loading: false,
    loading_reset: false,
    identifiant: null,
    password: null,
    email: null,
  }),

  validations: {
    identifiant: {
      required,
      minLength: minLength(5),
    },
    password: {
      required,
      minLength: minLength(8),
    },
    email: {
      required,
      email,
    },
  },

  computed: {
    // ...mapState('app', ['snackColor','snackText']),

    // snackText(){
    //   console.log(this.snackText, this.snackColor)
    //   return this.snackText
    // },

    logo() {
      return this.$store.state.app.logo;
    },

    sidebarOverlayGradiant() {
      return `${this.$store.state.app.loginBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`;
    },

    idErrors() {
      const errors = [];
      if (!this.$v.identifiant.$dirty) return errors;
      !this.$v.identifiant.required &&
        errors.push("L'identifiant est obligatoire");
      !this.$v.identifiant.minLength &&
        errors.push("L'identifiant doit comporter 5 caractères minimum");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push('Le mot de passe est obligatoire');
      !this.$v.password.minLength &&
        errors.push('Le mot de passe doit contenir 8 caractères minimum');
      return errors;
    },

    // resetpasswordErrors() {
    //   const errors = [];
    //   if (!this.$v.email.$dirty) return errors;
    //   !this.$v.email.required && errors.push('L\'adresse mail est obligatoire');
    //   !this.$v.email.email && errors.push('Entrez une adresse email valide');
    //   return errors;
    // }
  },

  mounted() {
    // clean the localstorage
    localStorage.removeItem('user');
  },

  methods: {
    ...mapActions('user', ['user_reset_password', 'user_login']),
    ...mapMutations('app', ['showSnack']),

    getPwd() {
      this.loading_reset = true;
      this.$v.email.$touch();
      // call $touch with only ONE input field (just email to get pwd, not the login form)
      if (this.$v.email.$invalid) {
        console.log('probleme form');
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: "Votre email n'est pas valide",
        });
      } else {
        // this.$store.dispatch('user_reset_password', { email: this.email })
        this.user_reset_password({ email: this.email })
          .then((response) => {
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'success',
              text:
                "<strong>Votre nouveau mot de passe vient d'être envoyé.</strong><br /> Vous pouvez vous connecter avec ce nouveau mot de passe.",
            });
          })
          .catch((error) => {
            // display Error
            if (error.status == 401) {
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text:
                  "<strong>Aucun compte n'existe.</strong><br /> Vérifiez votre email.",
              });
              console.log(
                "<strong>Aucun compte n'existe.</strong><br /> Vérifiez votre email."
              );
            } else {
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text: 'Problème rencontré : ' + error.message,
              });
              console.log(error.message);
            }
          })
          .finally(() => {
            this.loading_reset = false;
            this.dialog = false;
          });
      }
    },

    message() {
      this.$router.push({ path: `/contact` });
    },

    login() {
      this.loading = true;
      let credentials = { email: this.identifiant, password: this.password };
      // call $touch with only TWO input fields (not the email to get new pwd)
      this.$v.identifiant.$touch();
      this.$v.password.$touch();

      if (this.$v.identifiant.$invalid || this.$v.password.$invalid) {
        console.log('probleme form');
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: "Le formulaire n'est pas valide",
        });
      } else {
        // this.$store.dispatch('user/user_login', credentials)
        this.user_login(credentials)
          .then((response) => {
            console.log('login OK', response);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'success',
              text: 'Connexion effectuée',
            });
            this.log_login();
          })
          .catch((error) => {
            console.log('NOOOO', error);
            // display Error
            if (parseInt(error.status) == 401) {
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text:
                  "<strong>Échec d'authentification.</strong><br /> Vérifiez votre identifiant et/ou mot de passe.",
              });
            } else {
              this.snack({
                position: 'bottom',
                align: 'center',
                color: 'error',
                text:
                  '<strong>Connexion impossible :</strong> ' + error.message,
              });
              console.log(error.message);
            }
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
#app .container,
div#app,
#core-view {
  background: #fff !important;
  height: 100vh !important;
}
h1,
h2 {
  text-align: center !important;
  width: 100%;
}
.v-input--selection-controls {
  margin-top: 0;
}
.contact-card {
  margin-top: 30vh;
}
</style>
